<template>
	<div class="media">
		<img
			ref="img"
			v-if="data.mimeType != 'video/mp4'"
			:src="data.url"
			:alt="data.title"
			:width="data.width"
			:height="data.height"
			loading="lazy"
			@load="loaded = true"
			:class="{'show': loaded}"
		/>

		<video
			loop
			ref="video"
			:src="data.url"
			v-if="data.mimeType == 'video/mp4'"
			autoplay
			muted
			:class="{'show': loaded}"
		></video>
	</div>
</template>

<script setup>
const props = defineProps({
	data: {
		type: Object,
		required: true
	}
});

const video = ref(null)
const img = ref(null)
const loaded = ref(false)

const videoReady = async () => {
	await nextTick()
	loaded.value = true
}
onMounted(async () => {
	if (props.data.mimeType == 'video/mp4') {
		if (video.value.readyState >= 1) {
			videoReady()
		}
		
		video.value.addEventListener('loadeddata', async () => {
			if (video.value.readyState >= 1) {
				videoReady()
			}
		})
	} else {
		img.value.complete ? loaded.value = true : null
	}
})
</script>

<style lang="scss" scoped>
img,
video {
	opacity: 0;
	transition: opacity .35s ease .1s;

	&.show {
		opacity: 1;
	}
}
</style>